import { Alert, Radio } from 'antd';
import { Btn } from '../../../../../../components/Button';
import { InitiativeTag } from '../../../../../../components/initiative/InitiativeTag';
import { useWizardNavigation } from '../../../../../../components/WizardNavigationProvider';
import { gql } from '@apollo/client';
import { Step1Demo_InitiativeFragment } from '../../../../../../generated/graphql';

interface Props {
  initiatives: Step1Demo_InitiativeFragment[];
  selectedInitiative: string | null;
  onSelectInitiative: (id: string) => void;
}

export const Step1 = ({
  initiatives,
  selectedInitiative,
  onSelectInitiative,
}: Props) => {
  const wizard = useWizardNavigation();

  const handleChange = (id: string) => {
    wizard.unlockStep(wizard.currentStepPath);
    onSelectInitiative(id);
  };

  return (
    <div>
      <h3 className="mb">Select initiative</h3>
      <Radio.Group
        value={selectedInitiative}
        onChange={(e) => handleChange(e.target.value)}
      >
        <div>
          {initiatives.map((initiative) => (
            <div key={initiative.id} className="mb--l">
              <Radio value={initiative.id}>
                <span className="mr">{initiative.name}</span>
                <InitiativeTag tag={initiative.tag} />
              </Radio>
            </div>
          ))}
        </div>
      </Radio.Group>
      <Alert
        showIcon
        className="mb--xl mt"
        type="info"
        message="Please keep in mind that this advice is generated by AI and should be
          used as a tool to support decision-making, rather than a substitute
          for professional judgment."
      />
      <div className="mt--l pt--l flx flx--jc-flx-end borderTop">
        <Btn
          type="primary"
          onClick={wizard.goToNextStep}
          disabled={
            !!wizard.activeStep.nextStep &&
            wizard.isLocked(wizard.activeStep.nextStep)
          }
        >
          Generate Propositions
        </Btn>
      </div>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_TEAM_INITIATIVES = gql`
  fragment Step1Demo_Initiative on InitiativeWithLinks {
    id
    name
    domainId {
      tenantId
      itemId
    }
    tag {
      colorCode
      iconId
      title
    }
  }
`;
