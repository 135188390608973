import { stringSort } from '../../../../../../services/stringSort';
import {
  BaseInitiative,
  InitiativeNode,
  initiativeTreeBuilder,
} from '../../../../initiatives/InitiativesNavigation/initiativeTreeBuilder';

type TopInitiativeReportInput<T, U> = {
  initiativeWithLinks: T;
  allSubInitiativesWithLinks: U[];
};

export interface GigantishData<T, U> {
  initiativeWithLinks: T | U;
}

export type GigantishNode<T, U> = InitiativeNode<GigantishData<T, U>>;

export function gigantishTreeBuilder<
  T extends BaseInitiative,
  U extends BaseInitiative,
>(
  initiatives: TopInitiativeReportInput<T, U>[]
): InitiativeNode<GigantishData<T, U>>[] {
  const initiativesTree: InitiativeNode<GigantishData<T, U>>[] =
    initiatives
      .map((i) => {
        const subInitiativeNodes: InitiativeNode<GigantishData<T, U>>[] =
          i.allSubInitiativesWithLinks
            .map((si) => ({
              id: si.id,
              key: si.id,
              title: si.name,
              parentIsCompletedOrArchived: false,
              baseInitiative: si,
              data: {
                initiativeWithLinks: si,
              },
            }))
            .sort((a, b) => stringSort(a.title, b.title));

        return {
          id: i.initiativeWithLinks.domainId.itemId,
          key: i.initiativeWithLinks.domainId.itemId,
          title: i.initiativeWithLinks.name,
          baseInitiative: i.initiativeWithLinks,
          children: initiativeTreeBuilder(
            subInitiativeNodes,
            i.initiativeWithLinks.id,
            i.initiativeWithLinks.domainId.itemId,
            i.initiativeWithLinks.metadata.archived
          ),
          parentIsCompletedOrArchived: false,
          data: { initiativeWithLinks: i.initiativeWithLinks },
        };
      })
      .sort((a, b) => stringSort(a.title, b.title)) ?? [];

  return initiativesTree;
}
