import { gql } from '@apollo/client';
import { ArchiveInitiativeTable__InitiativeFragment } from '../../../../../generated/graphql';
import { useTranslation } from 'react-i18next';
import Table, { ColumnProps } from 'antd/es/table';
import { InitiativeTag } from '../../../../initiative/InitiativeTag';
import { stringSort } from '../../../../../services/stringSort';
import {
  getArchivedInitiativeNodes,
  simpleTreeBuilder,
  SimpleTreeNode,
} from '../manageCompanyInitiatives/ManageCompanyInitiatives';
import { useMemo } from 'react';
import { UnarchiveButton } from './UnarchiveButton';

interface Props {
  allInitiatives: ArchiveInitiativeTable__InitiativeFragment[];
}

export const ArchivedInitiativesTable = (props: Props) => {
  const { t } = useTranslation();

  const memoizedTree = useMemo(
    () => simpleTreeBuilder(props.allInitiatives),
    [props.allInitiatives]
  );

  const archivedNodes = getArchivedInitiativeNodes(memoizedTree);

  const initData = archivedNodes.map((node) => {
    return {
      initiative: node.initiative,
      childCount: countChildren(node),
    };
  });

  // for all top level initiatives, get the number of children recursively

  const initiativeColumns: ColumnProps<{
    initiative: ArchiveInitiativeTable__InitiativeFragment;
    childCount: number;
  }>[] = [
    {
      title: t('common.initiative.tag'),
      key: 'status',
      dataIndex: ['initiative', 'tag'],
      render: (tag) => {
        return <InitiativeTag tag={tag} />;
      },
    },
    {
      title: t('common.title'),
      key: 'name',
      sorter: (a, b) => stringSort(a.initiative.name, b.initiative.name),
      dataIndex: ['initiative', 'name'],
      render: (_, row) => (
        <div>
          {row.initiative.name}
          <div className="txt--secondary font-size--sm">
            {t('ArchivedInitiativesTable.childCount')}:{' '}
            <span className="bold">{row.childCount}</span>
          </div>
        </div>
      ),
    },

    {
      title: '',
      key: 'unarchive',
      width: 90,
      render: (_, row) => <UnarchiveButton initiative={row.initiative} />,
    },
  ];

  return (
    <Table
      columns={initiativeColumns}
      dataSource={initData}
      rowKey={(r) => r.initiative.id}
      pagination={{ hideOnSinglePage: true }}
    />
  );
};

// recursively count the total number of children for a given initiative
const countChildren = (initiative: SimpleTreeNode<any>): number => {
  let count = 0;
  if (initiative.children) {
    count += initiative.children.length;
    initiative.children.forEach((child) => {
      count += countChildren(child);
    });
  }
  return count;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const INITIATIVE_TABLE_QUERY = gql`
  fragment archiveInitiativeTable__Initiative on InitiativeWithLinks {
    domainId {
      itemId
      tenantId
    }
    name
    id
    tag {
      title
      iconId
      colorCode
    }
    metadata {
      completedAt
      archived
      status
      supportsInitiatives {
        id
        domainId {
          itemId
          tenantId
        }
      }
      supportsMigs {
        id
        domainId {
          itemId
          tenantId
        }
      }
    }
  }
`;
