import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const KebabMenuIconSvg = () => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="currentColor">
    <path d="M10 16.662c-.458 0-.85-.164-1.176-.49a1.605 1.605 0 0 1-.49-1.177c0-.458.163-.85.49-1.177.326-.327.718-.49 1.177-.49.458 0 .85.163 1.177.49.326.326.49.719.49 1.177 0 .458-.164.85-.49 1.177-.327.326-.719.49-1.177.49Zm0-5c-.458 0-.85-.164-1.176-.49a1.605 1.605 0 0 1-.49-1.177c0-.459.163-.85.49-1.177.326-.327.718-.49 1.177-.49.458 0 .85.163 1.177.49.326.326.49.718.49 1.177 0 .458-.164.85-.49 1.177-.327.326-.719.49-1.177.49Zm0-5c-.458 0-.85-.164-1.176-.49a1.605 1.605 0 0 1-.49-1.177c0-.459.163-.85.49-1.177.326-.327.718-.49 1.177-.49.458 0 .85.163 1.177.49.326.326.49.718.49 1.177 0 .458-.164.85-.49 1.177-.327.326-.719.49-1.177.49Z" />
  </svg>
);

export const KebabMenuIcon = (props: Omit<IconComponentProps, 'ref'>) => (
  <Icon component={KebabMenuIconSvg} {...props} />
);
