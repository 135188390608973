import {
  BaseInitiative,
  InitiativeNode,
  initiativeTreeBuilder,
} from '../../../../appPages/company/initiatives/InitiativesNavigation/initiativeTreeBuilder';
import { MilestoneStatus } from '../../../../generated/graphql';
import { stringSort } from '../../../../services/stringSort';

type MilestoneInfoWithStats = {
  milestone: Milestone;
  stats: ActivityAndMilestoneStats;
  initiativeDetails: {
    id: string;
    name: string;
    domainId: {
      itemId: string;
      tenantId: string;
    };
  };
};

type Milestone = {
  id: string;
  domainId: {
    itemId: string;
    tenantId: string;
  };
  name: string;
  deadlineAt: string;
  metadata: {
    completedAt: string | null;
    status: MilestoneStatus;
  };
};

type ActivityAndMilestoneStats = {
  completed: number;
  overdue: number;
  upcoming: number;
};

type AccumulatedMilestonesWithStats = {
  milestones: MilestoneInfoWithStats[];
  stats: {
    completed: number;
    overdue: number;
    upcoming: number;
  };
};

export interface InitiativeDetailsData {
  gantishInitiative: BaseInitiative;
  milestones: MilestoneInfoWithStats[];
  accumulatedMilestones: AccumulatedMilestonesWithStats;
  stats: {
    milestones: ActivityAndMilestoneStats;
    activities: ActivityAndMilestoneStats;
  };
}

export type InitiativeDetailsNode = InitiativeNode<InitiativeDetailsData>;

type InitiativeReportInput = {
  allSubInitiatives: {
    id: string;
    initiativeWithLinks: BaseInitiative;
    milestones: { milestone: Milestone; stats: ActivityAndMilestoneStats }[];
    stats: {
      milestones: ActivityAndMilestoneStats;
      activities: ActivityAndMilestoneStats;
    };
  }[];
  stats: {
    milestones: ActivityAndMilestoneStats;
    activities: ActivityAndMilestoneStats;
  };
  milestones: { milestone: Milestone; stats: ActivityAndMilestoneStats }[];
};

export function initiativeDetailsTreeBuilder(
  initiativeReport: InitiativeReportInput,
  topInitiative: BaseInitiative
): InitiativeNode<InitiativeDetailsData> {
  const subInitiativeNodes: InitiativeNode<InitiativeDetailsData>[] =
    initiativeReport.allSubInitiatives
      .map((si) => ({
        id: si.id,
        key: si.id,
        title: si.initiativeWithLinks.name,
        parentIsCompletedOrArchived: false,
        baseInitiative: si.initiativeWithLinks,
        data: {
          gantishInitiative: si.initiativeWithLinks,
          accumulatedMilestones: {
            milestones: [],
            stats: {
              completed: 0,
              overdue: 0,
              upcoming: 0,
            },
          },
          stats: si.stats,
          milestones: si.milestones.map((m) => ({
            ...m,
            initiativeDetails: {
              id: si.initiativeWithLinks.id,
              name: si.initiativeWithLinks.name,
              domainId: si.initiativeWithLinks.domainId,
            },
          })),
        },
      }))
      .sort((a, b) => stringSort(a.title, b.title));

  const gantishTopNode: InitiativeNode<InitiativeDetailsData> = {
    id: topInitiative.domainId.itemId,
    key: topInitiative.domainId.itemId,
    title: topInitiative.name,
    parentIsCompletedOrArchived: false,
    baseInitiative: topInitiative,
    data: {
      gantishInitiative: topInitiative,
      milestones: initiativeReport.milestones.map((m) => ({
        ...m,
        initiativeDetails: {
          id: topInitiative.id,
          name: topInitiative.name,
          domainId: topInitiative.domainId,
        },
      })),
      accumulatedMilestones: {
        milestones: [],
        stats: {
          completed: 0,
          overdue: 0,
          upcoming: 0,
        },
      },
      stats: initiativeReport.stats,
    },

    children: initiativeTreeBuilder<InitiativeDetailsData>(
      subInitiativeNodes,
      topInitiative.id,
      topInitiative.domainId.itemId,
      topInitiative.metadata.archived
    ),
  };

  // Function to recursively accumulate milestones and stats
  function accumulateMilestones(
    node: InitiativeNode<InitiativeDetailsData>
  ): AccumulatedMilestonesWithStats {
    let accumulatedMilestones: MilestoneInfoWithStats[] = [
      ...node.data.milestones,
    ];
    let accumulatedStats = {
      completed: 0,
      overdue: 0,
      upcoming: 0,
    };

    if (node.children) {
      node.children.forEach((child) => {
        const childAccumulated = accumulateMilestones(child);
        accumulatedMilestones = accumulatedMilestones.concat(
          childAccumulated.milestones
        );
        accumulatedStats.completed += childAccumulated.stats.completed;
        accumulatedStats.overdue += childAccumulated.stats.overdue;
        accumulatedStats.upcoming += childAccumulated.stats.upcoming;
      });
    }

    // Accumulate current node stats
    accumulatedStats.completed += node.data.stats.milestones.completed;
    accumulatedStats.overdue += node.data.stats.milestones.overdue;
    accumulatedStats.upcoming += node.data.stats.milestones.upcoming;

    node.data.accumulatedMilestones = {
      milestones: accumulatedMilestones,
      stats: accumulatedStats,
    };

    return node.data.accumulatedMilestones;
  }
  // Accumulate milestones and stats for the top node and its children
  accumulateMilestones(gantishTopNode);

  return gantishTopNode;
}
