import { Dropdown, MenuProps, Popconfirm } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  Action,
  ActivityActions_MitemFragment,
} from '../../../../../generated/graphql';
import { useTeamPermissions } from '../../../../../usePermissions';
import { CheckOutlined, CopyOutlined, EditOutlined } from '@ant-design/icons';
import { Btn } from '../../../../../components/Button';
import { useModal } from '../../../../../ModalProvider';

import { gql } from '@apollo/client';
import { DeleteOutlineIcon } from '../../../../../icons/initiativeIcons/DeleteOutlineIcon';
import { useMitemArchivation } from '../../../sprint/common/services/useMitemArchivation';
import { showNotification } from '../../../../../services/fetchNotificationProperties';
import { KebabMenuIcon } from '../../../../../icons/KebabMenuIcon';

type MenuItem = Required<MenuProps>['items'][number] & { include?: boolean };

interface Props {
  teamId: string;
  activity: ActivityActions_MitemFragment;
}

export const ActivityActions = ({ teamId, activity }: Props) => {
  const { t } = useTranslation();
  const { openModal } = useModal(); // Use modal context hook
  // Remove old hook instance

  const [archiveMitem, { loading: archivingLoading }] = useMitemArchivation({
    onCompleted() {
      showNotification('success', {
        message: t('ActivityActions.deleteSuccess'),
      });
    },
    onError() {
      showNotification('error', {
        message: t('ActivityActions.deleteError'),
      });
    },
  });

  const { isAllowed, loading } = useTeamPermissions({
    teamId: teamId,
    requestedAction: {
      action: Action.UPDATE,
      resource: 'sprintKA',
    },
  });

  const items: MenuItem[] = [
    {
      label: <div className="txt--action-blue">{t('common.edit')}</div>,
      key: 'EditMilestone',
      icon: <EditOutlined className="txt--action-blue font-size--lg" />,
      onClick: () => {
        openModal({
          type: 'editActivity',
          teamId: activity.teamId,
          activityId: activity.id,
        });
      },
      include: isAllowed,
    },
    {
      label: <div className="txt--action-blue">{t('common.copy')}</div>,
      key: 'CopyActivity',
      icon: <CopyOutlined className="txt--action-blue font-size--lg" />,
      onClick: () => {
        openModal({
          type: 'createActivity',
          formType: {
            type: 'copy',
            activityIdToCopyFrom: activity.id,
          },
          teamId: activity.teamId,
        });
      },
      include: isAllowed,
    },
    {
      label: (
        <div className="txt--action-blue">
          {t('ActivityActions.markAsCompleted')}
        </div>
      ),
      key: 'MarkAsCompleted',
      icon: <CheckOutlined className="txt--action-blue font-size--lg" />,
      onClick: () => {
        openModal({
          type: 'activityDetails',
          params: {
            activityId: activity.id,
            teamId: activity.teamId,
            activeTab: 'report',
          },
        });
      },
      include: isAllowed && !activity.completed,
    },
    {
      label: (
        <div onClick={(e) => e.stopPropagation()}>
          <Popconfirm
            placement="top"
            title={t('common.delete')}
            onConfirm={() => {
              archiveMitem(activity.teamId, activity.id);
            }}
            onCancel={(e) => e?.stopPropagation()}
            okText={t('common.yes')}
            cancelText={t('common.no')}
            disabled={archivingLoading}
          >
            <div className="flx ">
              <DeleteOutlineIcon className="txt--action-blue font-size--lg space--r" />
              <div className="txt--action-blue">{t('common.delete')}</div>
            </div>
          </Popconfirm>
        </div>
      ),
      key: 'DeleteMilestone',

      include: isAllowed,
    },
  ];

  const filteredItems = items.filter(
    (item) => 'include' in item === false || item.include === true
  );

  if (filteredItems.length === 0 || loading) {
    return null;
  }

  return (
    <>
      <div onClick={(e) => e.stopPropagation()}>
        <Dropdown menu={{ items: filteredItems }}>
          <Btn
            type="text"
            className="txt--action-blue font-size--lg"
            icon={<KebabMenuIcon />}
          />
        </Dropdown>
      </div>
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ActivityActions_Mitem = gql`
  fragment ActivityActions_Mitem on Mitem {
    id
    teamId
    completed
  }
`;
