import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useState, useEffect, useMemo } from 'react';
import { DatePicker, Divider } from 'antd';
import { ReportSection } from '../ReportSection';
import { SankeyProfitRevenueChart } from './SankeyProfitRevenueChart';
import { InitiativesBubbleChart } from './InitiativesBubbleChart';
import { InitiativeCardSection } from './InitiativeCardSection/InitiativeCardSection';
import {
  GetInitiativeDetailedReport2ForPdfDocument,
  GetTopInitiativesPdfReport2Document,
  InitiativeDetailedReportResponseFragment,
} from '../../../../generated/graphql';
import { InitiativeTag } from '../../../../components/initiative/InitiativeTag';
import { FreeTextComment } from '../components/FreeTextComment';
import { GigantishReport } from './Gigantish/GigantishReport';
import { chunkArray } from '../../../../services/array';
import dayjs, { Dayjs } from 'dayjs';

interface Props {
  isPreviewMode: boolean;
}

export const CompanyInitiativeReportSection = ({ isPreviewMode }: Props) => {
  const { t } = useTranslation();
  const { data } = useQuery(GetTopInitiativesPdfReport2Document);
  const [hiddenInitiatives, setHiddenInitiatives] = useState(new Set<string>());
  const [fetchInitiative] = useLazyQuery(
    GetInitiativeDetailedReport2ForPdfDocument
  );

  const [boundary, setBoundary] = useState<{ start: Dayjs; end: Dayjs }>({
    start: dayjs().startOf('year'),
    end: dayjs().endOf('year'),
  });

  const [initiativeReports, setInitiativeReports] = useState<
    InitiativeDetailedReportResponseFragment[]
  >([]);

  const companyInitiatives = useMemo(
    () =>
      data?.topInitiativeReport2.initiatives.filter((i) => {
        if (!i.companyInitiative) return false;
        if (isPreviewMode) {
          return !hiddenInitiatives.has(i.id);
        }
        return true;
      }) || [],
    [data?.topInitiativeReport2.initiatives, hiddenInitiatives, isPreviewMode]
  );

  const initiativeChunks = chunkArray(companyInitiatives, 3);

  const initReports = useMemo(
    () =>
      initiativeReports.filter((ir) =>
        isPreviewMode ? !hiddenInitiatives.has(ir.id) : true
      ),
    [hiddenInitiatives, initiativeReports, isPreviewMode]
  );

  useEffect(() => {
    if (companyInitiatives.length > 0) {
      const fetchAllReports = async () => {
        const results = [];
        for (const initiative of companyInitiatives) {
          const response = await fetchInitiative({
            variables: { initiativeId: initiative.domainId.itemId },
          });
          response.data?.initiativeDetailedReport &&
            results.push(response.data?.initiativeDetailedReport);
        }
        results && setInitiativeReports(results);
      };

      fetchAllReports();
    }
  }, [companyInitiatives, fetchInitiative]);

  return (
    <ReportSection
      isPreviewMode={isPreviewMode}
      title={t('CompanyInitiativeReportSection.headTitle')}
      subTitle={t('CompanyInitiativeReportSection.subTitle')}
    >
      {initiativeChunks.map((initiatives, index) => (
        <InitiativeCardSection
          key={index}
          isPreviewMode={isPreviewMode}
          companyInitiatives={initiatives}
          isHidden={(initId: string) => hiddenInitiatives.has(initId)}
          toggleHidden={(initiativeId) => {
            setHiddenInitiatives((prev) => {
              const newSet = new Set(prev);
              if (prev.has(initiativeId)) {
                newSet.delete(initiativeId);
              } else {
                newSet.add(initiativeId);
              }
              return newSet;
            });
          }}
        />
      ))}

      <div>
        <FreeTextComment isPreviewMode={isPreviewMode} className="mt--xxl" />
      </div>
      <div className="exclude-from-pdf">
        <Divider />
      </div>
      <div className="page-break">
        <div>
          <h3 className="unbreakable">
            {t('CompanyInitiativeReportSection.title')}
          </h3>
          {!isPreviewMode && (
            <div className="flx mt--l flx--ai-center ">
              <h5 className="mr--l">
                {t('CompanyInitiativeReportSection.graphPeriod')}
              </h5>
              <div>
                <span className="txt--secondary font-size--sm">
                  {t('CompanyInitiativeReportSection.from')}
                </span>
                <DatePicker
                  value={boundary.start}
                  allowClear={false}
                  className="mr"
                  picker="month"
                  onChange={(date) => {
                    if (date) {
                      setBoundary({
                        ...boundary,
                        start: date.startOf('month'),
                      });
                    }
                  }}
                />
                <span className="txt--secondary font-size--sm">
                  {t('CompanyInitiativeReportSection.to')}
                </span>
                <DatePicker
                  value={boundary.end}
                  allowClear={false}
                  picker="month"
                  onChange={(date) => {
                    if (date) {
                      setBoundary({ ...boundary, end: date.endOf('month') });
                    }
                  }}
                />
              </div>
            </div>
          )}

          {companyInitiatives.map((ci) => (
            <GigantishReport
              key={ci.id}
              topInitiativeReports={[ci]}
              boundary={boundary}
              isHidden={(initId: string) => hiddenInitiatives.has(initId)}
            />
          ))}
        </div>

        <FreeTextComment
          isPreviewMode={isPreviewMode}
          className="mt--xxl unbreakable"
        />
      </div>
      <div className="exclude-from-pdf">
        <Divider />
      </div>
      <div className="page-break vertically-center">
        <div className="pt--xl flx">
          <div className="flx--3 mr--l">
            <SankeyProfitRevenueChart initiatives={companyInitiatives} />
          </div>
          <div className="flx--3">
            {initReports.length > 0 && (
              <InitiativesBubbleChart
                initiativesDetails={initReports}
                initiatives={companyInitiatives.map(
                  (ci) => ci.initiativeWithLinks
                )}
              />
            )}
          </div>
        </div>
        <div className="flx flx--jc-center flx--wrap">
          {companyInitiatives.map((ci) => (
            <div key={ci.id} className="mr--l">
              <InitiativeTag
                useBgColor
                tag={ci.initiativeWithLinks.tag}
                hideTitle
                className="mr--xs"
              />
              {ci.initiativeWithLinks.name}
            </div>
          ))}
        </div>
      </div>
    </ReportSection>
  );
};

// GraphQL queries
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_INITIATIVES_SANKEY = gql`
  query getTopInitiativesPdfReport2(
    $tenantId: ID
    $filter: TopInitiativeFilter
  ) {
    topInitiativeReport2(tenantId: $tenantId, filter: $filter) {
      initiatives {
        id
        domainId {
          itemId
          tenantId
        }
        companyInitiative
        initiativeWithLinks {
          id
          ...InitiativesBubbleChart__Initiative
        }
        ...CompanyInitiativeReportSection__TopInitiativeReportResponse2
        ...GigantishReport__TopInitiativeReport
        ...SankeyProfitRevenueChart__TopInitiativeReportResponse2
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_TENANT_INITIATIVES = gql`
  query getInitiativeDetailedReport2ForPdf($tenantId: ID, $initiativeId: ID!) {
    initiativeDetailedReport(tenantId: $tenantId, initiativeId: $initiativeId) {
      id
      ...InitiativeDetailedReportResponse
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_INITIATIVE_DETAILED_REPORT = gql`
  fragment InitiativeDetailedReportResponse on InitiativeDetailedReportResponse {
    id
    ...InitiativesBubbleChart__InitiativeDetailedReportResponse
  }
`;
