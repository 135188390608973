import { MinusSquareOutlined, PlusSquareOutlined } from '@ant-design/icons';
import { atLeastOne } from '../../../../../../../services/error-parser/helpers';
import { GigantishTreeNode } from '../../Gigantish';
import cx from 'classnames';

import './GigantishLegendInitiative.less';
import { InitiativeValue } from './components/InitiativeValue';
import { GigantishLegendInitiativeSkeleton } from './GigantishLegendInitiative.skeleton';
import { Link } from 'react-router-dom';
import { Popover } from 'antd';
import { useTranslation } from 'react-i18next';

interface Props {
  initiativeNode: GigantishTreeNode;
  expandedInitiatives: string[];
  isSubInitiative?: boolean;
  onToggle: (id: string) => void;
  unit: string;
}

export const GigantishLegendInitiative = ({
  initiativeNode,
  expandedInitiatives,
  isSubInitiative = false,
  unit,
  onToggle,
}: Props) => {
  const { t } = useTranslation();

  const hasChildren = atLeastOne(initiativeNode.children);
  const isExpanded = expandedInitiatives.includes(initiativeNode.id);
  const status = initiativeNode.data.initiativeWithLinks.metadata.status;

  return (
    <div className="GigantishLegendInitiative gantishShared-AppendAnimation">
      <div
        key={initiativeNode.id}
        className={cx('GigantishLegendInitiative__row', {
          'GigantishLegendInitiative__row--small': isSubInitiative,
        })}
      >
        {hasChildren && (
          <div
            className="GigantishLegendInitiative__toggle"
            onClick={() => onToggle(initiativeNode.id)}
          >
            {isExpanded ? <MinusSquareOutlined /> : <PlusSquareOutlined />}
          </div>
        )}
        <span className="GigantishLegendInitiative__name">
          <Link
            className="GigantishLegendInitiative__link"
            to={`/company/initiatives/${initiativeNode.baseInitiative.domainId.itemId}`}
          >
            {initiativeNode.baseInitiative.name}
          </Link>
        </span>

        <div className="GigantishLegendInitiative__value">
          <Popover
            placement="topRight"
            title={t('common.profit')}
            content={
              <FinancialPopover
                value={initiativeNode.data.initiativeWithLinks.profit}
                unit={unit}
              />
            }
          >
            <div>
              <InitiativeValue
                value={initiativeNode.data.initiativeWithLinks.profit}
                deprecatedStatus={status}
                archived={
                  initiativeNode.data.initiativeWithLinks.metadata.archived
                }
              />
            </div>
          </Popover>
          <Popover
            placement="topRight"
            title={t('common.revenue')}
            content={
              <FinancialPopover
                value={initiativeNode.data.initiativeWithLinks.revenue}
                unit={unit}
              />
            }
          >
            <div>
              <InitiativeValue
                value={initiativeNode.data.initiativeWithLinks.revenue}
                deprecatedStatus={status}
                archived={
                  initiativeNode.data.initiativeWithLinks.metadata.archived
                }
              />
            </div>
          </Popover>
        </div>
      </div>
      {isExpanded && (
        <div>
          {initiativeNode.children?.map((subInitiative) => (
            <GigantishLegendInitiative
              key={subInitiative.id}
              initiativeNode={subInitiative}
              expandedInitiatives={expandedInitiatives}
              isSubInitiative={true}
              onToggle={onToggle}
              unit={unit}
            />
          ))}
        </div>
      )}
    </div>
  );
};

GigantishLegendInitiative.skeleton = GigantishLegendInitiativeSkeleton;

const FinancialPopover = ({
  value,
  unit,
}: {
  value: { potential?: number | null; current?: number | null } | null;
  unit: string;
}) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="flx">
        <div className="mr">{t('FinancialPopover.current')}:</div>
        <div className="bold ml--auto space--r">{value?.current ?? '-'}</div>
        {unit}
      </div>
      <div className="flx">
        <div className="mr">{t('FinancialPopover.potential')}:</div>
        <div className="bold ml--auto space--r">{value?.potential ?? '-'}</div>
        {unit}
      </div>
    </>
  );
};
