import dayjs from 'dayjs';
import { GigantishTreeNode, daysToPx } from '../Gigantish';
import './GigantishChartInitiative.less';
import cx from 'classnames';
import { DurationStatus } from '../../../../../../generated/graphql';
import { Link } from 'react-router-dom';

interface Props {
  initiative: GigantishTreeNode;
  expandedInitiatives: string[];
  boundary: {
    start: dayjs.Dayjs;
    end: dayjs.Dayjs;
  };
  windowStart: dayjs.Dayjs;
  isSubInitiative?: boolean;
}

export const GigantishChartInitiative = ({
  initiative,
  windowStart,
  expandedInitiatives,
  boundary,
  isSubInitiative = false,
}: Props) => {
  const daysLong = dayjs(
    initiative.data.initiativeWithLinks.endAt ??
      initiative.data.initiativeWithLinks.metadata.completedAt ??
      boundary.end.subtract(1, 'month')
  ).diff(initiative.data.initiativeWithLinks.startAt ?? boundary.start, 'days');

  const daysSinceBoundaryStart = dayjs(
    initiative.data.initiativeWithLinks.startAt
  ).diff(boundary.start, 'days');

  const isExpanded = expandedInitiatives.includes(initiative.id);
  const status = initiative.data.initiativeWithLinks.metadata.status;

  return (
    <div className="">
      <div
        key={initiative.id}
        className={cx('GigantishChartInitiative__row', {
          'GigantishChartInitiative__row--small': isSubInitiative,
        })}
      >
        <Link
          to={`/company/initiatives/${initiative.baseInitiative.domainId.itemId}`}
          className={cx(
            'GigantishChartInitiative__bar  gantishShared-AppendAnimation',
            {
              'GigantishChartInitiative__bar--completed':
                status === DurationStatus.COMPLETED,
              'GigantishChartInitiative__bar--archived':
                initiative.data.initiativeWithLinks.metadata.archived,
              'GigantishChartInitiative__bar--upcoming':
                status === DurationStatus.UPCOMING,
              'GigantishChartInitiative__bar--overdue':
                status === DurationStatus.OVERDUE,
              'GigantishChartInitiative__bar--active':
                status === DurationStatus.ACTIVE,
            }
          )}
          style={{
            marginLeft: daysToPx(daysSinceBoundaryStart),
            width: daysToPx(daysLong),
          }}
        ></Link>
      </div>
      {isExpanded &&
        initiative.children?.map((subInitiative) => (
          <GigantishChartInitiative
            key={subInitiative.id}
            initiative={subInitiative}
            expandedInitiatives={expandedInitiatives}
            boundary={boundary}
            windowStart={windowStart}
            isSubInitiative={true}
          />
        ))}
    </div>
  );
};
