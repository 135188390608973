import { gql, useSuspenseQuery } from '@apollo/client';
import { GetInitiativesForNavigationDocument } from '../../../../generated/graphql';
import { InitiativeTreeWrapper } from './initiativeTreeWrapper/InitiativeTreeWrapper';
import { useTranslation } from 'react-i18next';
import { InitiativesNavigationSkeleton } from './InitiativesNavigation.skeleton';
import { useMemo } from 'react';
import { navigationTreeBuilder } from './initiativeTreeWrapper/navigationTreeBuilder';
import { InitiativeSearchInput } from './InitiativeSearchInput';
import { stringSort } from '../../../../services/stringSort';

export const InitiativesNavigation = () => {
  const { t } = useTranslation();
  const { data } = useSuspenseQuery(GetInitiativesForNavigationDocument);

  const { links } = data.listTenantLinks;

  const nonArchivedInitiatives = data.initiatives.initiatives.filter(
    (i) => i.metadata.archived == false
  );

  const memoizedNavigationTree = useMemo(
    () =>
      navigationTreeBuilder(
        nonArchivedInitiatives.sort((a, b) => stringSort(a.name, b.name))
      ),
    [nonArchivedInitiatives]
  );

  const companyInitiatives = memoizedNavigationTree.filter((i) =>
    links.some((l) => l.domainId.itemId === i.baseInitiative.domainId.itemId)
  );

  const otherInitiatives = memoizedNavigationTree.filter(
    (i) => !companyInitiatives.includes(i)
  );

  return (
    <div>
      <div className="flx mb--l mr--l">
        <InitiativeSearchInput
          initiatives={nonArchivedInitiatives}
          className="flx--1"
        />
      </div>

      <div className="mb">
        <h3 className="mb--xs">
          {t('InitiativesNavigation.companyInitiatives')}
        </h3>
        <InitiativeTreeWrapper treeData={companyInitiatives} />
      </div>
      <div>
        <h3 className="mb--xs">
          {t('InitiativesNavigation.otherInitiatives')}
        </h3>
        <InitiativeTreeWrapper treeData={otherInitiatives} />
      </div>
    </div>
  );
};

InitiativesNavigation.skeleton = InitiativesNavigationSkeleton;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_INITIATIVES_NAVIGATION = gql`
  query getInitiativesForNavigation($tenantId: ID) {
    initiatives(tenantId: $tenantId) {
      initiatives {
        ...initiativeTreeWrapper__Initiative
        ...InitiativeSearchInput__Initiative
      }
    }
    listTenantLinks(tenantId: $tenantId, type: INITIATIVE) {
      links {
        id
        domainId {
          itemId
          tenantId
        }
      }
    }
  }
`;
