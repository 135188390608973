import { gql, useSuspenseQuery } from '@apollo/client';
import { InitiativeHeaderSection } from '../../company/initiatives/initiativeDetails/headerSection/InitiativeHeaderSection';
import {
  GetInitiativeReportForDetailsTabDocument,
  InitiativeDetailsTab__InitiativeFragment,
} from '../../../generated/graphql';

interface Props {
  initiative: InitiativeDetailsTab__InitiativeFragment;
}

export const InitiativeDetailsTab = ({ initiative }: Props) => {
  const { data } = useSuspenseQuery(GetInitiativeReportForDetailsTabDocument, {
    variables: {
      initiativeId: initiative.domainId.itemId,
    },
  });

  const initiativeReport = data.initiativeDetailedReport;

  return (
    <InitiativeHeaderSection
      initiative={initiative}
      initiativeReport={initiativeReport}
    />
  );
};

InitiativeDetailsTab.Skeleton = InitiativeHeaderSection.Skeleton;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const INITIATIVE_HEADER_SECTION__INITIATIVE2 = gql`
  fragment InitiativeDetailsTab__Initiative on InitiativeWithLinks {
    id
    ...InitiativeHeaderSection__Initiative
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const INITIATIVE_HEADER_SECTION__INITIATIVE_DETAILED_REPORT_RESPONSE = gql`
  fragment InitiativeDetailsTab__InitiativeDetailedReport on InitiativeDetailedReportResponse {
    id
    ...InitiativeHeaderSection__InitiativeDetailedReportResponse
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_TENANT_INITIATIVES = gql`
  query GetInitiativeReportForDetailsTab($tenantId: ID, $initiativeId: ID!) {
    initiativeDetailedReport(tenantId: $tenantId, initiativeId: $initiativeId) {
      id
      ...InitiativeDetailsTab__InitiativeDetailedReport
    }
  }
`;
