import { gql } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { CompanyInitiativeReportSection__TopInitiativeReportResponse2Fragment } from '../../../../../generated/graphql';
import { InitiativeIcon } from '../../../../../components/initiative/InitiativeIcon';
import './InitiativeCardSection.less';
import { CheckIcon } from '../../../../../icons/CheckIcon';
import { WarningIcon } from '../../../../../icons/WarningIcon';
import { UpcomingEventIcon } from '../../../../../icons/UpcomingEventIcon';
import { Colors } from '../../../../componentLibrary/Colors';
import { Btn } from '../../../../../components/Button';

interface Props {
  companyInitiatives: CompanyInitiativeReportSection__TopInitiativeReportResponse2Fragment[];
  isHidden: (initiativeId: string) => boolean;
  toggleHidden: (initiativeId: string) => void;
  isPreviewMode: boolean;
}

export const InitiativeCardSection = ({
  companyInitiatives,
  toggleHidden,
  isHidden,
  isPreviewMode,
}: Props) => {
  const { t } = useTranslation();

  return (
    <div
      className={'InitiativeCardSection vertically-center unbreakable mt--xl'}
    >
      {companyInitiatives.map((initiative) => {
        const { completed, overdue, upcoming } =
          initiative.accumulatedStats.milestones;
        const totalMilestones = completed + overdue + upcoming;
        const isHiddenInitiative = isHidden(initiative.id);
        const hasProgress = totalMilestones > 0;
        return (
          <div
            key={initiative.id}
            className="InitiativeCardSection__card"
            style={{ opacity: isHiddenInitiative && !isPreviewMode ? 0.2 : 1 }}
          >
            <h4>
              <span style={{ fontSize: 20 }}>
                <InitiativeIcon
                  iconId={initiative.initiativeWithLinks.tag.iconId}
                />
              </span>
              <span className="ml--s">
                {initiative.initiativeWithLinks.name}
              </span>
            </h4>
            <SubHeading>{t('InitiativeCardSection.purpose')}</SubHeading>
            <div style={{ minHeight: 80 }} className="font-size--xs">
              {initiative.initiativeWithLinks.description}
            </div>
            <SubHeading className="">
              {t('InitiativeCardSection.supportedMig')}
            </SubHeading>
            <div className="font-size--xs">
              {initiative.initiativeWithLinks.metadata.supportsMigs.map(
                (mig) => mig.mig.name
              )}
            </div>
            <SubHeading className="mb--s">
              {t('InitiativeCardSection.milestoneProgress')}
            </SubHeading>
            <div className="flx flx--jc-space-between">
              <div className="mr--s">
                <CheckIcon className="mr--s font-size--default txt--success" />
                <span className="space--r bold">{completed}</span>
                {t('common.completed')}
              </div>
              <div className="mr--s">
                <WarningIcon
                  className="mr--s font-size--default"
                  style={{ color: Colors.Status.OVERDUE }}
                />
                <span className="space--r bold">{overdue}</span>
                {t('common.overdue')}
              </div>
              <div className="mr--s">
                <UpcomingEventIcon
                  className="mr--s font-size--default"
                  style={{ color: Colors.Status.FUTURE_PURPLE }}
                />
                <span className="space--r bold">{upcoming}</span>
                {t('common.upcoming')}
              </div>
            </div>
            <div className="flx mt">
              {!hasProgress && <NoProgressSegment />}
              {hasProgress && (
                <>
                  <ProgressSegment
                    ratio={completed / totalMilestones}
                    color={Colors.Status.OK_GREEN}
                  />
                  <ProgressSegment
                    ratio={overdue / totalMilestones}
                    color={Colors.Status.OVERDUE}
                  />
                  <ProgressSegment
                    ratio={upcoming / totalMilestones}
                    color={Colors.Status.FUTURE_PURPLE}
                  />
                </>
              )}
            </div>
            {!isPreviewMode && (
              <Btn
                type="link"
                size="small"
                className="mt font-size--sm"
                onClick={() => toggleHidden(initiative.id)}
              >
                {isHiddenInitiative
                  ? t('InitiativeCardSection.showInitiative')
                  : t('InitiativeCardSection.hideInitiative')}
              </Btn>
            )}
          </div>
        );
      })}
    </div>
  );
};

type SubHeadingProps = {
  children: React.ReactNode;
  className?: string;
};

const SubHeading = ({ children, className }: SubHeadingProps) => (
  <h5 className={'bold mt mb--xs ' + (className ?? '')}>{children}</h5>
);

interface ProgressSegmentProps {
  ratio: number;
  color: string;
}

const ProgressSegment = ({ ratio, color }: ProgressSegmentProps) => (
  <div
    style={{
      height: 4,
      width: `${ratio * 100}%`,
      backgroundColor: color,
    }}
  />
);

const NoProgressSegment = () => (
  <div
    style={{
      height: 4,
      width: `100%`,
      backgroundColor: Colors.Grays.FRAMES_AND_LINES,
    }}
  />
);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_INITIATIVES_FOR_CARD_SECTION = gql`
  fragment CompanyInitiativeReportSection__TopInitiativeReportResponse2 on TopInitiativeReport2 {
    id
    domainId {
      itemId
      tenantId
    }
    companyInitiative
    accumulatedStats {
      milestones {
        completed
        overdue
        upcoming
      }
    }
    initiativeWithLinks {
      id
      name
      description
      domainId {
        itemId
        tenantId
      }
      tag {
        iconId
        colorCode
        title
      }
      profit {
        potential
        current
      }
      revenue {
        potential
        current
      }
      metadata {
        status
        supportsMigs {
          id
          mig {
            id
            name
          }
        }
      }
    }
  }
`;
