import { gql } from '@apollo/client';
import {
  DurationStatus,
  InitiativeSearchInput__InitiativeFragment,
} from '../../../../generated/graphql';
import Select, { DefaultOptionType, SelectProps } from 'antd/es/select';
import { InitiativeTag } from '../../../../components/initiative/InitiativeTag';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { stringSort } from '../../../../services/stringSort';

interface Props {}

interface Props extends SelectProps {
  initiatives: InitiativeSearchInput__InitiativeFragment[];
}

interface Option extends DefaultOptionType {
  initiative: InitiativeSearchInput__InitiativeFragment | null;
}

export const InitiativeSearchInput = ({ initiatives, ...props }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();

  const sortedInitiatives = initiatives.sort((a, b) =>
    stringSort(a.tag.title, b.tag.title)
  );

  const options: Option[] = [
    {
      initiative: null,
      label: <span>{t('InitiativeSearchInput.activeInitiatives')}</span>,
      options: sortedInitiatives
        .filter((i) => i.metadata.status === DurationStatus.ACTIVE)
        .map((i) => ({
          initiative: i,
          value: i.domainId.itemId,
          label: (
            <div>
              <InitiativeTag tag={i.tag} hideTitle className="mr" />
              {i.tag.title}
            </div>
          ),
        })),
    },
    {
      initiative: null,
      label: <span>{t('InitiativeSearchInput.upcomingInitiatives')}</span>,
      options: sortedInitiatives
        .filter((i) => i.metadata.status === DurationStatus.UPCOMING)
        .map((i) => ({
          initiative: i,
          value: i.domainId.itemId,
          label: (
            <div>
              <InitiativeTag tag={i.tag} hideTitle className="mr" />
              {i.tag.title}
            </div>
          ),
        })),
    },
  ];

  return (
    <Select
      {...props}
      style={{ width: '100%' }}
      showSearch
      allowClear
      placeholder={t('InitiativeSearchInput.searchInitiatives')}
      onChange={(itemId) => {
        const initiative = initiatives.find(
          (i) => i.domainId.itemId === itemId
        );
        if (initiative) {
          history.push(
            '/company/initiatives/' + initiative.domainId.itemId + ``
          );
        }
      }}
      options={options}
      filterOption={(input, option) => {
        if (!option?.initiative) return false;

        return (
          option.initiative.name.toLowerCase().includes(input.toLowerCase()) ||
          option.initiative.tag.title
            .toLowerCase()
            .includes(input.toLowerCase())
        );
      }}
    />
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const INITIATIVE_TREE_WRAPPER__INITIATIVE = gql`
  fragment InitiativeSearchInput__Initiative on InitiativeWithLinks {
    domainId {
      itemId
      tenantId
    }
    name
    id
    tag {
      iconId
      colorCode
      title
    }
    metadata {
      status
      archived
    }
  }
`;
