import { useTranslation } from 'react-i18next';
import { UnarchiveOutlinedIcon } from '../../../../../icons/UnarchiveIcon';
import { Btn } from '../../../../Button';
import { gql, useMutation, useQuery } from '@apollo/client';
import { showNotification } from '../../../../../services/fetchNotificationProperties';
import { howweErrorParser } from '../../../../../services/howweErrorParser';
import {
  GetInitiativeForUnarchiveButtonDocument,
  GetInitiativesForNavigationDocument,
  UnarchiveInitiativeButton__InitiativeFragment,
  UnarchiveInitiativeButton_MutationDocument,
} from '../../../../../generated/graphql';
import { Popconfirm } from 'antd';

interface Props {
  initiative: UnarchiveInitiativeButton__InitiativeFragment;
}

export const UnarchiveButton = ({ initiative }: Props) => {
  const { t } = useTranslation();
  const { data, loading: loadingInitiative } = useQuery(
    GetInitiativeForUnarchiveButtonDocument,
    {
      variables: { initiativeId: initiative.domainId.itemId },
      fetchPolicy: 'no-cache',
    }
  );

  const [unarchive, { loading: unArchivePending }] = useMutation(
    UnarchiveInitiativeButton_MutationDocument,
    {
      onError: (error) => {
        const howweErrors = howweErrorParser(error);

        showNotification('error', {
          message: t('UnarchiveButton.saveError'),
          description: (
            <strong>
              <ul>
                {howweErrors?.map((e, i) => <li key={i}>{e.translation}</li>)}
              </ul>
            </strong>
          ),
        });
      },
      refetchQueries: () => [{ query: GetInitiativesForNavigationDocument }],
    }
  );

  return (
    <Popconfirm
      title={t('UnarchiveButton.unarchive')}
      description={t('UnarchiveButton.description')}
      onConfirm={() => {
        if (data?.initiative) {
          unarchive({
            variables: {
              initiativeId: data?.initiative.domainId.itemId,
              initiative: { archived: false, rev: data?.initiative.rev },
            },
          });
        }
      }}
      okText="Yes"
      cancelText="No"
      disabled={loadingInitiative || unArchivePending}
    >
      <Btn
        loading={unArchivePending}
        type="link"
        icon={<UnarchiveOutlinedIcon />}
        title={t('UnarchiveButton.unarchive')}
      />
    </Popconfirm>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const UNARCHIVE_MUTATION = gql`
  mutation unarchiveInitiativeButton_mutation(
    $initiativeId: ID!
    $initiative: UpdateInitiative2Input!
  ) {
    updateInitiative(initiativeId: $initiativeId, initiative: $initiative) {
      id
      name
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_INITIATIVE_FOR_MARK_AS_ARCHIVED = gql`
  query getInitiativeForUnarchiveButton($tenantId: ID, $initiativeId: ID!) {
    initiative(tenantId: $tenantId, initiativeId: $initiativeId) {
      id
      domainId {
        itemId
      }
      name
      rev
      metadata {
        archived
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const INITIATIVE_FRAGMENT = gql`
  fragment unarchiveInitiativeButton__Initiative on InitiativeWithLinks {
    id
    domainId {
      itemId
    }
    name
    metadata {
      archived
    }
  }
`;
