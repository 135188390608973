import { Suspense, useState } from 'react';
import { ActivityCommitmentStepSkeleton } from './ActivityCommitmentStep.Skeleton';
import { MonthBasedCommitments } from './components/MonthBasedCommitments';
import { OwnerBasedCommitments } from './components/OwnerBasedCommitments';
import { InitiativeBasedCommitments } from './components/InitiativeBasedCommitments'; // Import the new component
import { Radio } from 'antd';

interface Props {
  teamId: string;
}

export const ActivityCommitmentStep = ({ teamId }: Props) => {
  const [view, setView] = useState<View>(getStoredView());

  // Update handler that also persists to localStorage
  const handleViewChange = (newView: View) => {
    setView(newView);
    saveViewToStorage(newView);
  };

  return (
    <div className="mr--xxl ml--xxl">
      <h2>Commitments</h2>
      <div className="flx flx--ai-center flx--gap--s mt--l mb--xl pb--l borderBottom borderTop pt--l">
        <span>Activities grouped by:</span>
        <Radio.Group
          onChange={(e) => handleViewChange(e.target.value)}
          value={view}
        >
          <Radio.Button value="Month" className="semi-bold">
            Deadline
          </Radio.Button>
          <Radio.Button value="Owner" className="semi-bold">
            Owner
          </Radio.Button>
          <Radio.Button value="Initiatives" className="semi-bold">
            Initiatives
          </Radio.Button>
        </Radio.Group>
      </div>

      {/* Conditionally render based on view state */}
      {/* Wrap in Suspense for potential loading states within components */}
      <Suspense fallback={<ActivityCommitmentStepSkeleton />}>
        {view === 'Month' && <MonthBasedCommitments teamId={teamId} />}
        {view === 'Owner' && <OwnerBasedCommitments teamId={teamId} />}
        {/* Render the new component for Initiatives view */}
        {view === 'Initiatives' && (
          <InitiativeBasedCommitments teamId={teamId} />
        )}
      </Suspense>
    </div>
  );
};
ActivityCommitmentStep.Skeleton = ActivityCommitmentStepSkeleton;

type View = 'Month' | 'Owner' | 'Initiatives';

const LOCAL_STORAGE_VIEW_KEY = 'commitmentViewType';

// Helper function to get stored view from localStorage
const getStoredView = (): View => {
  const storedView = localStorage.getItem(LOCAL_STORAGE_VIEW_KEY);
  return (storedView as View) || 'Month';
};

// Helper function to save view to localStorage
const saveViewToStorage = (view: View): void => {
  localStorage.setItem(LOCAL_STORAGE_VIEW_KEY, view);
};
