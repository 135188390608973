import dayjs from 'dayjs';
import { GigantishReportTreeNode, daysToPercentage } from '../GigantishReport';
import './GigantishReportChartInitiative.less';
import cx from 'classnames';
import { DurationStatus } from '../../../../../../generated/graphql';
import { InitiativeIcon } from '../../../../../../components/initiative/InitiativeIcon';

interface Props {
  initiative: GigantishReportTreeNode;
  isHidden: (initiativeId: string) => boolean;
  boundary: {
    start: dayjs.Dayjs;
    end: dayjs.Dayjs;
  };
}

export const GigantishReportChartInitiative = ({
  initiative,
  boundary,
  isHidden,
}: Props) => {
  const daysLong = calculateDaysLong(
    initiative.data.initiativeWithLinks.startAt,
    initiative.data.initiativeWithLinks.endAt,
    initiative.data.initiativeWithLinks.metadata.completedAt,
    boundary.start,
    boundary.end
  );

  const daysSinceBoundaryStart = dayjs(
    initiative.data.initiativeWithLinks.startAt
  ).diff(boundary.start, 'days');

  const totalDaysWithinBoundary = boundary.end.diff(boundary.start, 'days') + 1;

  const status = initiative.data.initiativeWithLinks.metadata.status;

  return (
    <div style={{ opacity: isHidden(initiative.baseInitiative.id) ? 0.2 : 1 }}>
      <div className="flx GigantishReportChartInitiative__row">
        <h4 className="GigantishReportChartInitiative__title">
          <span className="mr--s">
            <InitiativeIcon iconId={initiative.baseInitiative.tag.iconId} />
          </span>
          {initiative.baseInitiative.tag.title}
        </h4>
        <div
          key={initiative.id}
          className="GigantishReportChartInitiative--chartSection"
        >
          <div
            className={cx('GigantishReportChartInitiative__bar', {
              'GigantishReportChartInitiative__bar--completed':
                status === DurationStatus.COMPLETED,
              'GigantishReportChartInitiative__bar--archived':
                initiative.data.initiativeWithLinks.metadata.archived,
              'GigantishReportChartInitiative__bar--upcoming':
                status === DurationStatus.UPCOMING,
              'GigantishReportChartInitiative__bar--overdue':
                status === DurationStatus.OVERDUE,
              'GigantishReportChartInitiative__bar--active':
                status === DurationStatus.ACTIVE,
            })}
            style={{
              left: daysToPercentage(
                daysSinceBoundaryStart,
                totalDaysWithinBoundary
              ),
              position: 'absolute',
              width: daysToPercentage(daysLong, totalDaysWithinBoundary),
            }}
          ></div>
        </div>
      </div>

      {initiative.children?.map((subInitiative) => {
        const daysLong = calculateDaysLong(
          subInitiative.data.initiativeWithLinks.startAt,
          subInitiative.data.initiativeWithLinks.endAt,
          subInitiative.data.initiativeWithLinks.metadata.completedAt,
          boundary.start,
          boundary.end
        );
        const daysSinceBoundaryStart = dayjs(
          subInitiative.data.initiativeWithLinks.startAt
        ).diff(boundary.start, 'days');

        const subInitiativeStatus =
          subInitiative.data.initiativeWithLinks.metadata.status;

        return (
          <div
            className="flx GigantishReportChartInitiative__row--small"
            key={subInitiative.id}
          >
            <h5 className="GigantishReportChartInitiative__title--small txt--secondary">
              {subInitiative.baseInitiative.tag.title}
            </h5>
            <div
              key={initiative.id}
              className="GigantishReportChartInitiative--chartSection"
            >
              <div
                className={cx(
                  'GigantishReportChartInitiative__bar GigantishReportChartInitiative__bar--small',
                  {
                    'GigantishReportChartInitiative__bar--completed':
                      subInitiativeStatus === DurationStatus.COMPLETED,
                    'GigantishReportChartInitiative__bar--archived':
                      subInitiative.data.initiativeWithLinks.metadata.archived,
                    'GigantishReportChartInitiative__bar--upcoming':
                      subInitiativeStatus === DurationStatus.UPCOMING,
                    'GigantishReportChartInitiative__bar--overdue':
                      subInitiativeStatus === DurationStatus.OVERDUE,
                    'GigantishReportChartInitiative__bar--active':
                      subInitiativeStatus === DurationStatus.ACTIVE,
                  }
                )}
                style={{
                  left: daysToPercentage(
                    daysSinceBoundaryStart,
                    totalDaysWithinBoundary
                  ),
                  position: 'absolute',
                  width: daysToPercentage(daysLong, totalDaysWithinBoundary),
                }}
              ></div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const calculateDaysLong = (
  initiativeStartAt: string | null,
  initiativeEndAt: string | null,
  completedAt: string | null | undefined,
  boundaryStart: dayjs.Dayjs,
  boundaryEnd: dayjs.Dayjs
) => {
  return dayjs(
    initiativeEndAt ?? completedAt ?? boundaryEnd.add(1, 'month')
  ).diff(initiativeStartAt ?? boundaryStart, 'days');
};
