import { gql, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import {
  InitiativeHeaderSection__InitiativeDetailedReportResponseFragment,
  InitiativeHeaderSection_FinancialsMutationDocument,
  MigStatus,
  Action,
  InitiativeHeaderSection__InitiativeFragment,
} from '../../../../../generated/graphql';
import { DisplayName } from '../../../../../components/DisplayName';
import { InitiativeStatus } from '../../../../../components/initiative/InitiativeStatus';
import { InitiativeGraph } from './components/InitiativeGraph/InitiativeGraph';
import { InitiativeHeaderSectionSkeleton } from './InitiativeHeaderSection.skeleton';
import './InitiativeHeaderSection.less';
import { InitiativeValue } from '../../../strategicPlan/components/Gigantish/components/GigantishLegendInitiative/components/InitiativeValue';
import ClampText from '../../../../../components/ClampText';
import { Btn } from '../../../../../components/Button';
import { useState } from 'react';
import {
  financialPotentialSchema,
  UpdateFinancialPotentialForm,
} from './components/UpdateFinancialPotentialForm';
import { Form, Modal, Tooltip } from 'antd';
import {
  MilestoneOrActiveToggle,
  MilestoneOrActiveToggleEnum,
} from '../milestoneOrActivityToggle/MilestoneOrActiveToggle';
import { WarningOutlineIcon } from '../../../../../icons/initiativeIcons/WarningOutlineIcon';
import { useTenantDetails } from '../../../../../hooks/useTenantDetails';
import { PermissionChecker } from '../../../../../PermissionChecker';
import { WarningOutlined } from '@ant-design/icons';

type Props = {
  initiative: InitiativeHeaderSection__InitiativeFragment;
  initiativeReport: InitiativeHeaderSection__InitiativeDetailedReportResponseFragment;
};

export const InitiativeHeaderSection = ({
  initiative,
  initiativeReport,
}: Props) => {
  const { t } = useTranslation();
  const [isFinancialPotentialModalOpen, setIsFinancialPotentialModalOpen] =
    useState(false);
  const [milestonesOrActivities, setMilestonesOrActivities] =
    useState<MilestoneOrActiveToggleEnum>(
      MilestoneOrActiveToggleEnum.Milestones
    );
  const { tenant } = useTenantDetails();
  const graphData =
    milestonesOrActivities === MilestoneOrActiveToggleEnum.Milestones
      ? initiativeReport.accumulatedStats.milestones
      : initiativeReport.accumulatedStats.activities;

  const [financialPotentialForm] = Form.useForm();

  const [updateInitiativeFinancials, { loading: updatePending }] = useMutation(
    InitiativeHeaderSection_FinancialsMutationDocument
  );

  const handleUpdateFinancialPotential = async () => {
    const unsafeValues = await financialPotentialForm.validateFields();
    const values = financialPotentialSchema.parse(unsafeValues);

    await updateInitiativeFinancials({
      variables: {
        initiativeId: initiative.domainId.itemId,
        initiative: values,
      },
    });
    setIsFinancialPotentialModalOpen(false);
  };

  const supportedMig = initiative.metadata.supportsMigs[0];

  const supportedInitiatives = initiative.metadata.supportsInitiatives;

  return (
    <div className="InitiativeHeaderSection">
      <div className="flx--1 pr--xxl mr--xl pa--xl">
        <div className="mb--l">
          <h5 className="txt--secondary">
            {t('InitiativeHeaderSection.statusLabel')}
          </h5>
          <h3>
            <InitiativeStatus
              status={initiative.metadata.status}
              archived={initiative.metadata.archived}
            />
          </h3>
        </div>
        <h5 className="txt--secondary mb">
          {t('InitiativeHeaderSection.purposeAndObjectives')}
        </h5>
        <ClampText
          maxLines={8}
          key={initiative.id}
          modalTitle={t('InitiativeHeaderSection.purposeAndObjectives')}
        >
          <div className="preserveLinebreaks">{initiative.description}</div>
          <ul className="mt">
            {initiative?.objectives.map((ob, index) => (
              <li key={index} className="mb--skipLast">
                {ob.text}
              </li>
            ))}
          </ul>
        </ClampText>
      </div>
      <div className="InitiativeHeaderSection__rightSection pa--xl">
        <div className="mb--xl">
          <h5 className="flx txt--secondary mb">
            <span className="mr--auto">
              {t('InitiativeHeaderSection.progress')}
            </span>
            <MilestoneOrActiveToggle
              radioClassName="font-size--sm"
              value={milestonesOrActivities}
              onChange={setMilestonesOrActivities}
            />
          </h5>
          <InitiativeGraph
            height={100}
            periodData={graphData.graph}
            legendCompleted={graphData.completed}
            legendGoal={graphData.total}
            startDate={initiative.startAt}
            endDate={initiative.endAt}
          />
        </div>

        <div className="InitiativeHeaderSection__meta">
          <div>
            <h5 className="txt--secondary mb--xs flx flx--ai-center">
              {t('InitiativeHeaderSection.accountable')}
            </h5>
            {initiative?.assignedTo.map((assignee) => (
              <div key={assignee.id}>
                <DisplayName user={assignee.data} />
              </div>
            ))}
            {initiative?.assignedTo.length === 0 && (
              <Tooltip title={t('InitiativeHeaderSection.noOwnerTooltip')}>
                <WarningOutlined className="InitiativeHeaderSection__ownerWarning" />
              </Tooltip>
            )}
          </div>
          <div>
            <h5 className="txt--secondary mb--xs flx flx--ai-center">
              {t('InitiativeHeaderSection.financialValueLabel')}
              <UpdateFinancialsButton
                onClick={() => setIsFinancialPotentialModalOpen(true)}
              />
            </h5>
            <div className="flx flx--jc-space-between">
              {t('common.profit')}
              <InitiativeValue value={initiative.profit} unit={tenant?.unit} />
            </div>
          </div>
          {supportedMig ? (
            <div>
              <h5 className="txt--secondary mb--xs">
                {t('InitiativeHeaderSection.supportsMig')}
                {supportedMig.mig.status === MigStatus.ARCHIVED && (
                  <Tooltip
                    title={t('InitiativeHeaderSection.supportsMigArchived')}
                  >
                    <WarningOutlineIcon
                      className="ml--xs txt--warning"
                      style={{ transform: 'scale(2) translateX(2px)' }}
                    />
                  </Tooltip>
                )}
              </h5>

              <div
                key={supportedMig.id}
                className="lineClamp--2"
                title={supportedMig.mig.name}
              >
                {supportedMig.mig.name}
              </div>
            </div>
          ) : (
            <div>
              <h5 className="txt--secondary mb--xs">
                {t('InitiativeHeaderSection.supportsInitiative')}
              </h5>
              {supportedInitiatives.map((supported) => (
                <div
                  key={supported.id}
                  className="lineClamp--2"
                  title={supported.data.name}
                >
                  {supported.data.name}
                </div>
              ))}
              {supportedInitiatives.length === 0 && <div>--</div>}
            </div>
          )}
          <div>
            <h5 className="txt--secondary mb--xs flx flx--ai-center">
              {t('InitiativeHeaderSection.financialValueLabel')}
              <UpdateFinancialsButton
                onClick={() => setIsFinancialPotentialModalOpen(true)}
              />
            </h5>
            <div className="flx flx--jc-space-between">
              {t('common.revenue')}
              <InitiativeValue value={initiative.revenue} unit={tenant?.unit} />
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={isFinancialPotentialModalOpen}
        width={300}
        onCancel={() => setIsFinancialPotentialModalOpen(false)}
        title={t('InitiativeHeaderSection.updateFinancialPotentialModalTitle')}
        onOk={handleUpdateFinancialPotential}
        okButtonProps={{ loading: updatePending }}
      >
        <UpdateFinancialPotentialForm
          initiative={initiative}
          form={financialPotentialForm}
        />
      </Modal>
    </div>
  );
};

InitiativeHeaderSection.Skeleton = InitiativeHeaderSectionSkeleton;

const UpdateFinancialsButton = (props: { onClick: () => void }) => {
  const { t } = useTranslation();
  return (
    <PermissionChecker
      resourceOwner={{
        type: 'TenantResource',
        requestedAction: [
          {
            resource: 'initiative',
            action: [Action.UPDATE],
          },
        ],
      }}
    >
      <Btn
        type="link"
        size="small"
        className="ml--auto InitiativeHeaderSection__updateBtn"
        onClick={props.onClick}
      >
        <span className="font-size--sm bold">{t('common.update')}</span>
      </Btn>
    </PermissionChecker>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const INITIATIVE_HEADER_SECTION__INITIATIVE_DETAILED_REPORT_RESPONSE = gql`
  fragment InitiativeHeaderSection__InitiativeDetailedReportResponse on InitiativeDetailedReportResponse {
    id
    domainId {
      itemId
      tenantId
    }
    accumulatedStats {
      subInitiatives {
        total
      }

      milestones {
        completed
        total
        graph {
          date
          completed
          total
        }
      }
      activities {
        completed
        total
        graph {
          date
          completed
          total
        }
      }
    }
    companyInitiative
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const INITIATIVE_HEADER_SECTION__INITIATIVE2 = gql`
  fragment InitiativeHeaderSection__Initiative on InitiativeWithLinks {
    id
    domainId {
      itemId
      tenantId
    }
    rev
    name
    description
    assignedTo {
      id
      data {
        id
        name
        email
        displayName
        initials
      }
    }
    startAt
    endAt
    objectives {
      text
      completed
    }
    tag {
      title
      iconId
      colorCode
    }
    metadata {
      status
      completedAt
      archived
      supportsInitiatives {
        id
        data {
          id
          name
        }
      }
      supportsMigs {
        id
        mig {
          id
          name
          status
        }
      }
    }
    profit {
      potential
      current
    }
    revenue {
      potential
      current
    }
    ...UpdateFinancialPotentialForm_Initiative
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const UPDATE_FINANCIAL_POTENTIAL_MODALCONTENT_MUTATION = gql`
  mutation InitiativeHeaderSection_financialsMutation(
    $initiativeId: ID!
    $initiative: UpdateInitiative2Input!
  ) {
    updateInitiative(initiativeId: $initiativeId, initiative: $initiative) {
      id
      ...UpdateFinancialPotentialForm_Initiative
    }
  }
`;
