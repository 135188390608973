import { RightOutlined } from '@ant-design/icons';
import cx from 'classnames';
import './ExpandArrow.less';
import { SmallCollapseClosedIcon } from '../icons/SmallCollapseClosedIcon';

interface Props {
  expanded: boolean;
  onClick?: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
  className?: string;
}

export const ExpandArrow = ({ onClick, expanded, className }: Props) => {
  return (
    <RightOutlined
      className={cx(
        'ExpandArrow__toggle',
        {
          'ExpandArrow__toggle--expanded': expanded,
        },
        className
      )}
      onClick={onClick}
    />
  );
};
export const ExpandArrowSolid = ({ onClick, expanded, className }: Props) => {
  return (
    <SmallCollapseClosedIcon
      className={cx(
        'ExpandArrowSolid__toggle',
        {
          'ExpandArrowSolid__toggle--expanded': expanded,
        },
        className
      )}
      onClick={onClick}
    />
  );
};
