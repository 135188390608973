import { ConfigProvider, Switch } from 'antd';
import { useAccelerationMeeting } from '../AccelerationMeetingProvider';
import { Colors } from '../../../../../componentLibrary/Colors';

interface Props {
  activityId: string;
  userId: string;
}

export const ActivityCommitmentSwitch = ({ activityId, userId }: Props) => {
  const { sprintKeyActivityContext } = useAccelerationMeeting();

  const usersCommitments =
    sprintKeyActivityContext.getUsersCommitments(activityId);
  const ownerCommitment = usersCommitments?.commitment;

  const handleChange = (checked: boolean) => {
    sprintKeyActivityContext.setUserCommitment(activityId, {
      committed: checked,
      userId,
    });
  };

  return (
    <ConfigProvider theme={{ token: { colorPrimary: Colors.Status.OK_GREEN } }}>
      <Switch checked={ownerCommitment?.committed} onChange={handleChange} />
    </ConfigProvider>
  );
};
