import { useMemo } from 'react';
import './GigantishReport.less';
import { Dayjs } from 'dayjs';
import { GigantishReportChartColumns } from './components/GigantishReportChartColumns';
import { GigantishReportChartInitiative } from './components/GigantishReportChartInitiative';
import { gql } from '@apollo/client';
import {
  DurationStatus,
  GigantishReport__TopInitiativeReportFragment,
} from '../../../../../generated/graphql';
import { GigantishReportSkeleton } from './GigantishReport.skeleton';
import {
  GigantishNode,
  gigantishTreeBuilder,
} from '../../../strategicPlan/components/Gigantish/components/GigantishTreeBuilder';

type GigantishReportInitiative = {
  startAt: string | null;
  endAt: string | null;
  metadata: {
    status: DurationStatus;
    completedAt?: string | null;
    archived?: boolean;
  };
};

export type GigantishReportTreeNode = GigantishNode<
  GigantishReportInitiative,
  GigantishReportInitiative
>;

interface Props {
  boundary: { start: Dayjs; end: Dayjs };
  isHidden: (initiativeId: string) => boolean;
  topInitiativeReports: GigantishReport__TopInitiativeReportFragment[];
}

export const GigantishReport = ({
  topInitiativeReports,
  boundary,
  isHidden,
}: Props) => {
  const memoizedGigantishReportTree = useMemo(() => {
    return gigantishTreeBuilder(topInitiativeReports ?? []);
  }, [topInitiativeReports]);

  return (
    <div className="mt--l unbreakable">
      <div className="GigantishReport">
        <div className="GigantishReport__chartColumns">
          <GigantishReportChartColumns boundary={boundary} />
        </div>
        <div className="GigantishReport__rows">
          {memoizedGigantishReportTree.map((initiative) => {
            return (
              <GigantishReportChartInitiative
                key={initiative.id}
                isHidden={isHidden}
                initiative={initiative}
                boundary={boundary}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

GigantishReport.skeleton = GigantishReportSkeleton;

export const daysToPercentage = (
  days: number,
  totalDaysWithinBoundary: number
) => (days / totalDaysWithinBoundary) * 100 + '%';

interface InitData {
  id: string;
  name: string;
  completed?: boolean;
  archived?: boolean;
  startAt: string;
  endAt?: string;
  revenue?: {
    potential?: number;
    current?: number;
  };
  profit?: {
    potential?: number;
    current?: number;
  };
  subInitiatives?: Array<InitData>;
}

export interface InitiativeNode extends InitData {
  subInitiatives?: Array<InitiativeNode>;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GIGANTISH_TOP_INITIATIVE_REPORT = gql`
  fragment GigantishReport__TopInitiativeReport on TopInitiativeReport2 {
    id
    initiativeWithLinks {
      ...GigantishReport__Initiative
    }
    allSubInitiativesWithLinks {
      ...GigantishReport__Initiative
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GIGANTISH__INITIATIVE = gql`
  fragment GigantishReport__Initiative on InitiativeWithLinks {
    domainId {
      itemId
      tenantId
    }
    startAt
    endAt
    name
    id
    tag {
      ...gigantishReport__InitiativeTag
    }
    metadata {
      completedAt
      archived
      status
      supportsInitiatives {
        id
        domainId {
          itemId
          tenantId
        }
      }
      supportsMigs {
        id
        domainId {
          itemId
          tenantId
        }
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GIGANTISH__INITIATIVE_TAG = gql`
  fragment gigantishReport__InitiativeTag on InitiativeTag {
    title
    iconId
    colorCode
  }
`;
