import { gql, useQuery } from '@apollo/client';
import {
  GetOverdueMilestonesDocument,
  MilestoneStatus,
  OverdueMilestones_InitiativeFragment,
} from '../../../../../generated/graphql';
import { InitiativeIcon } from '../../../../../components/initiative/InitiativeIcon';
import { friendlyUsername } from '../../../../../services/friendlyUsername';
import { friendlyDate } from '../../../../../services/dateFormats';
import './OverdueMilestones.less';
import TextArea from 'antd/es/input/TextArea';
import { useState } from 'react';
import { Empty } from 'antd';
import { useTranslation } from 'react-i18next';

interface Props {
  initiative: OverdueMilestones_InitiativeFragment;
  isPreviewMode: boolean;
}

export const OverdueMilestones = ({ initiative, isPreviewMode }: Props) => {
  const { t } = useTranslation();
  const [overdueComment, setOverdueComment] = useState<Record<string, string>>(
    {}
  );

  const { data } = useQuery(GetOverdueMilestonesDocument, {
    variables: { initiativeId: initiative.domainId.itemId },
  });

  const overdueMilestones = data?.milestonesForInitiative.milestones
    .filter((m) => m.metadata.status === MilestoneStatus.OVERDUE)
    .sort((a, b) => a.deadlineAt.localeCompare(b.deadlineAt));

  if (isPreviewMode && !overdueMilestones?.length) {
    return null;
  }

  return (
    <div className="OverdueMilestones unbreakable">
      <h3 className="mb--s">
        <InitiativeIcon iconId={initiative.tag.iconId} />
        <span className="ml">{initiative.name}</span>
      </h3>
      <table className="fullWidth">
        <thead>
          <tr>
            <th>{t('OverdueMilestones.deadline')}</th>
            <th>{t('OverdueMilestones.milestone')}</th>
            <th>{t('OverdueMilestones.assignedTo')}</th>
            <th>{t('OverdueMilestones.commentOnDelay')}</th>
          </tr>
        </thead>
        <tbody>
          {overdueMilestones?.map((milestone) => (
            <tr key={milestone.id}>
              <td className="txt--warning bold">
                {friendlyDate(milestone.deadlineAt, {
                  alwaysIncludeYear: true,
                })}
              </td>
              <td>
                <div className="bold">{milestone.name}</div>
                <div className="txt--secondary">
                  {milestone.metadata.supportsInitiatives
                    .map((i) => i.data.name)
                    .join(', ')}
                </div>
              </td>

              <td>
                {milestone.assignedTo
                  .map((u) => u.data.name || friendlyUsername(u.data))
                  .join(', ')}
              </td>
              <td>
                {isPreviewMode ? (
                  <div className="txt--secondary preserveLinebreaks">
                    {overdueComment[milestone.id]}
                  </div>
                ) : (
                  <TextArea
                    placeholder={t('OverdueMilestones.addCommentPlaceholder')}
                    rows={1}
                    value={overdueComment[milestone.id]}
                    onChange={(e) => {
                      setOverdueComment((current) => ({
                        ...current,
                        [milestone.id]: e.target.value,
                      }));
                    }}
                  />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {!overdueMilestones?.length && (
        <div>
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            className="flx--jc-center flx--ai-center"
            description={t('OverdueMilestones.noData')}
          />
        </div>
      )}
    </div>
  );
};

// eslint-disable-next-line
const OVERDUE_MILESTONES_FRAGMENT = gql`
  fragment OverdueMilestones_initiative on InitiativeWithLinks {
    id
    domainId {
      itemId
    }
    name
    tag {
      title
      iconId
    }
  }
`;

// eslint-disable-next-line
const OVERDUE_MILESTONES_QUERY = gql`
  query GetOverdueMilestones($initiativeId: ID!) {
    milestonesForInitiative(initiativeId: $initiativeId) {
      milestones {
        id
        domainId {
          itemId
        }
        name
        deadlineAt
        assignedTo {
          id
          data {
            id
            name
            email
            displayName
          }
        }
        metadata {
          status
          supportsInitiatives {
            id
            data {
              id
              name
            }
            domainId {
              itemId
            }
          }
        }
      }
    }
  }
`;
