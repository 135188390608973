import dayjs from 'dayjs';
import { MonthSlices } from './MonthSlices';
import './ChartSubinitiative.less';
import { MonthCell } from './MonthCell';
import { ChartSubinitiativeSkeleton } from './ChartSubinitiativeSkeleton';
import { gql } from '@apollo/client';
import { InitiativeDetailsTreeNode } from '../../../../../appPages/company/initiatives/initiativeDetails/InitiativeDetails';

export const ChartSubinitiative = ({
  initiative,
  months,
  window,
  expanded,
}: {
  initiative: InitiativeDetailsTreeNode;
  months: dayjs.Dayjs[];
  expanded: string[];
  window: {
    start: dayjs.Dayjs;
    end: dayjs.Dayjs;
  };
}) => {
  const isExpanded = expanded.includes(initiative.id);

  return (
    <div>
      <div
        className="ChartSubinitiative gantishShared-AppendAnimation "
        key={initiative.id}
      >
        {months.map((m, i) => (
          <MonthCell key={i} startOfMonth={m}>
            <MonthSlices
              startOfMonth={m}
              initiativeNode={initiative}
              window={window}
              showAggregated={isExpanded === false || isExpanded == null}
            />
          </MonthCell>
        ))}
      </div>
      {isExpanded &&
        initiative.children?.map((subInitiative) => (
          <ChartSubinitiative
            initiative={subInitiative}
            months={months}
            window={window}
            key={subInitiative.id}
            expanded={expanded}
          />
        ))}
    </div>
  );
};

ChartSubinitiative.Skeleton = ChartSubinitiativeSkeleton;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GANTISH_CHART__INITIATIVE = gql`
  fragment chartSubinitiative__Initiative on InitiativeWithLinks {
    id
    startAt
    endAt
  }
`;
