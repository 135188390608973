import { gql, useQuery } from '@apollo/client';
import { ReportSection } from '../ReportSection';
import { GetTopInitiativesForOverdueTrackerInReportDocument } from '../../../../generated/graphql';
import { OverdueMilestones } from './components/OverdueMilestones';
import { useTranslation } from 'react-i18next';
import { Empty } from 'antd';

interface Props {
  isPreviewMode: boolean;
}

export const OverdueMilestonesReportSection = ({ isPreviewMode }: Props) => {
  const { t } = useTranslation();
  const { data, loading } = useQuery(
    GetTopInitiativesForOverdueTrackerInReportDocument
  );

  const hasNoInitiatives =
    data?.topInitiativeReport2.initiatives.length === 0 && !loading;

  if (hasNoInitiatives) {
    return (
      <ReportSection
        isPreviewMode={isPreviewMode}
        title={t('OverdueMilestonesReportSection.title')}
        subTitle={t('OverdueMilestonesReportSection.subTitle')}
      >
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          style={{ marginTop: '50px' }}
          description={t('OverdueMilestonesReportSection.noData')}
        />
      </ReportSection>
    );
  }

  return (
    <ReportSection
      isPreviewMode={isPreviewMode}
      title={t('OverdueMilestonesReportSection.title')}
      subTitle={t('OverdueMilestonesReportSection.subTitle')}
    >
      {data?.topInitiativeReport2.initiatives.map((initiative) => (
        <OverdueMilestones
          key={initiative.id}
          isPreviewMode={isPreviewMode}
          initiative={initiative.initiativeWithLinks}
        />
      ))}
    </ReportSection>
  );
};

//eslint-disable-next-line
const INITIATIVES_QUERY = gql`
  query GetTopInitiativesForOverdueTrackerInReport(
    $tenantId: ID
    $filter: TopInitiativeFilter
  ) {
    topInitiativeReport2(tenantId: $tenantId, filter: $filter) {
      initiatives {
        id
        initiativeWithLinks {
          id
          ...OverdueMilestones_initiative
        }
      }
    }
  }
`;
