import { gql } from '@apollo/client';
import { InitiativesTree } from './initaitiveTree/InitiativesTree';
import { useRouteMatch } from 'react-router-dom';
import { InitiativeNode } from '../initiativeTreeBuilder';
interface Props {
  treeData: InitiativeNode[];
}

export const InitiativeTreeWrapper = ({ treeData }: Props) => {
  const match = useRouteMatch<{ initiativeId: string }>(
    '/company/initiatives/:initiativeId'
  );

  const initiativeId = match?.params.initiativeId ?? undefined;

  return treeData.length > 0 ? (
    <InitiativesTree
      key={initiativeId}
      selectedInitiativeId={initiativeId}
      treeData={treeData}
    />
  ) : (
    <div className="txt--secondary">-</div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const INITIATIVE_TREE_WRAPPER__INITIATIVE = gql`
  fragment initiativeTreeWrapper__Initiative on InitiativeWithLinks {
    domainId {
      itemId
      tenantId
    }
    startAt
    endAt
    name
    id
    tag {
      ...initiativeTreeWrapper__InitiativeTag
    }
    metadata {
      completedAt
      archived
      status
      supportsInitiatives {
        id
        domainId {
          itemId
          tenantId
        }
      }
      supportsMigs {
        id
        domainId {
          itemId
          tenantId
        }
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const INITIATIVE_TREE_WRAPPER__INITIATIVE_TAG = gql`
  fragment initiativeTreeWrapper__InitiativeTag on InitiativeTag {
    title
    iconId
    colorCode
  }
`;
